import TextButton, { TextButtonMode, TextButtonProps } from "./TextButton";
import { useMemo } from "react";

function UnderlinedTextButton({ mode, ...props }: TextButtonProps & { href: string }) {

  const textButtonClassName = useMemo(() => {
    switch (mode) {
      case TextButtonMode.NEGATIVE:
        return "btn-negative-text";

      case TextButtonMode.PRIMARY:
        return "btn-primary-text";

      default:
        return "btn-primary-text";
    }

  }, [mode]);


  return <TextButton {...props}
                     className={`${textButtonClassName} ${props.className}}`}
                     textClassName={`clickable text-black-100 fs-16px text-decoration-underline ${props.textClassName ?? ""}`} />;
}

export default UnderlinedTextButton;
