import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";
import { Link } from "react-router-dom";

export type BlackButtonWithIconProps = {
  readonly onClick?: () => void;
  readonly text?: string;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
  readonly href?: string
};

function BlackButton(props: BlackButtonWithIconProps) {
  if (props.href) {
    return <Link to={props.href}><ButtonWithIcon theme="black" {...props} /></Link>;
  }

  return <ButtonWithIcon theme="black" {...props} />;
}

export default BlackButton;
