import { CheckCircle } from "@mui/icons-material";
import { Snackbar } from "@mui/material";
import Card from "components/card/Card";
import Row from "components/custom/Row";

import InboxDangerIcon from "assets/icons/InboxDangerIcon";
import XIcon from "assets/icons/XIcon";
import { BlackBodyText100 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useTranslation } from "react-i18next";
import { AlertType } from "types/alert.types";

export default function AlertSnackProvider() {
  const { t } = useTranslation("common");

  const { isOpen, type, message, handleCloseAlert } = useAlertManager();

  const isSuccess = type === AlertType.SUCCESS;
  const iconColor = isSuccess ? "#23D206" : "#680031";

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleCloseAlert}
    >
      <div>
        <Card
          padding="p-2"
          className={`d-flex flex-row align-items-center justify-between rounded-xxl ${
            isSuccess ? "bg-alert-green" : "bg-cherry-800"
          }`}
        >
          <Row className="align-items-center flex-nowrap">
            <div className="w-min-25px"> {isSuccess ? <CheckCircle htmlColor={iconColor} /> : <InboxDangerIcon />}</div>

            <div style={{ paddingTop: 2 }} className="ml-1">
              <BlackBodyText100> {message ?? t("error.unknown")}</BlackBodyText100>
            </div>
          </Row>
          <div className="clickable ml-4 w-25px" onClick={handleCloseAlert}>
            <XIcon fill={"black"} width="12" />
          </div>
        </Card>
      </div>
    </Snackbar>
  );
}
