import { VehicleChargingDetail } from "types/vehicle.types";

import {
  BaseCharging,
  Charging,
  ChargingPaymentState,
  ChargingProvider,
  TotalEnergyAndCostByPeriodAndProvider
} from "types/charging.types";

import BluedotInAppIcon from "assets/icons/BluedotInAppIcon";
import ChargerIconAC from "assets/icons/ChargerIconAC";
import ChargerIconDC from "assets/icons/ChargerIconDC";
import HomeIcon from "assets/icons/HomeIcon";
import TeslaIcon from "assets/icons/TeslaIcon";
import { OrganizationCharging } from "types/organization.types";
import { PayerType } from "types/payment.types";
import { getMonthNameFromYearWeekFormat, getWeekIntervalFromYearWeekFormat } from "./date.utils";
import { getFormattedCostByProvider } from "./format.utils";
import { getVehicleSummariesAsObjectForCsv } from "./vehicle.utils";
import OtherNetworkIcon from "../assets/icons/OtherNetworkIcon";
import { AppColors } from "./colors";


export const getWhoPaysDisplayTextByPayerType = (payer?: PayerType) => {
  switch (payer) {
    case PayerType.DRIVER:
      return "Driver";
    case PayerType.ORGANIZATION:
      return "Organization";
    default:
      return "-";
  }
};

export const getChargingPayStatDisplayText = (paymentState: ChargingPaymentState) => {
  return getChargingPayStatDisplayContent(paymentState).label;
};

export const getChargingPayStatDisplayContent = (paymentState: ChargingPaymentState) => {
  switch (paymentState) {
    case ChargingPaymentState.PAID:
      return {
        label: "Paid",
        textClass: "success-text",
        color: "#2BA318"
      };

    case ChargingPaymentState.UNPAID:
      return {
        label: "Unpaid",
        textClass: "text-cherry-100",
        color: "#C4003E"
      };
    default:
      return {
        label: "-",
        textClass: "",
        color: "#C4003E"
      };
  }
};

export const getChargerTypeIcon = (type): JSX.Element => {
  switch (type) {
    case "AC":
      return <ChargerIconAC />;
    case "DC":
      return <ChargerIconDC />;
    default:
      return <ChargerIconAC />;
  }
};


export function getChargerInfo(provider: ChargingProvider) {
  switch (provider) {
    case ChargingProvider.TESLA:
      return {
        logo: <TeslaIcon />,
        label: "Tesla",
        color: "#BF3D62"
      };
    case ChargingProvider.EVGO:
      return {
        logo: <BluedotInAppIcon />,
        label: "EVgo",
        color: "#2F6265"
      };

    case ChargingProvider.ELECTRIFY_AMERICA:
      return {
        logo: <BluedotInAppIcon />,
        label: "Electrify America",
        color: "#A9D564"
      };
    case ChargingProvider.CHARGE_POINT:
      return {
        logo: <BluedotInAppIcon />,
        label: "ChargePoint",
        color: "#FE7A30"
      };

    case ChargingProvider.HOME:
      return {
        logo: <HomeIcon />,
        label: "Home",
        color: "#C89FFF",
        sortIndex: 1
      };

    case ChargingProvider.FLO:
      return {
        logo: <BluedotInAppIcon />,
        label: "FLO",
        color:  AppColors.blue800,
        sortIndex: 1
      };

    case ChargingProvider.TESLA_DESTINATION:
      return {
        logo: <TeslaIcon />,
        label: "Tesla Destination",
        color: "#BF3D62"
      };

    default:
      return {
        logo: <OtherNetworkIcon />,
        label: "Other",
        color: "#909090",
        sortIndex: 2
      };
  }
}

const getProviderProperties = getChargerInfo;

export function convertSecondsToMinutesAndHours(seconds) {
  if (seconds === 0) {
    return seconds;
  }

  if (!seconds) {
    return "-";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (minutes === 0 && hours === 0) {
    return `${remainingSeconds}s`;
  }
  if (hours === 0) {
    return `${remainingMinutes}m ${remainingSeconds}s`;
  }
  return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
}

export const getUniquePeriodsFromStatistics = (statisticsByPeriod: TotalEnergyAndCostByPeriodAndProvider[]) => {
  const uniquePeriodsSet = new Set<string>();

  statisticsByPeriod.forEach((statistic) => {
    uniquePeriodsSet.add(statistic.period);
  });

  return Array.from(uniquePeriodsSet);
};

export const getMonthLabelForStatisticGraphByStatisticData = (
  statisticsByPeriod: TotalEnergyAndCostByPeriodAndProvider[]
) => {
  const uniquePeriods = getUniquePeriodsFromStatistics(statisticsByPeriod);
  const monthPeriods = uniquePeriods.map((p) => getMonthNameFromYearWeekFormat(p));
  const uniqueMonthsSet = new Set<string>();
  monthPeriods.forEach((m) => {
    uniqueMonthsSet.add(m);
  });
  return { monthlyLabels: Array.from(uniqueMonthsSet), uniquePeriods };
};

export const getTooltipWeekLabelForStatisticGraphByYearWeekFormat = (weekYear: string) => {
  const { startOfTheWeek, nextOfTheWeek } = getWeekIntervalFromYearWeekFormat(weekYear);

  return `${startOfTheWeek} - ${nextOfTheWeek}`;
};
export const getSessionStatisticDataForGraphAsWeekOfLastQuarter = (
  statisticsByPeriod: TotalEnergyAndCostByPeriodAndProvider[]
) => {
  const providersMap = new Map();
  const periodsSet = new Set();

  statisticsByPeriod.forEach(({ period }) => {
    periodsSet.add(period);
  });
  const periods = Array.from(periodsSet);

  statisticsByPeriod.forEach(({ provider, totalCost, period }) => {
    if (!provider) {
      return;
    }

    if (!providersMap.has(provider)) {
      const { label, color, sortIndex } = getProviderProperties(provider as ChargingProvider);
      providersMap.set(provider, {
        data: new Array(periods.length).fill(0),
        label: label,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        sortIndex: sortIndex ?? 0
      });
    }

    const existingProvider = providersMap.get(provider);
    const periodIndex = periods.indexOf(period);
    existingProvider.data[periodIndex] = totalCost / 100;
  });

  const providers = Array.from(providersMap.values());

  providers.sort((n1, n2) => n1.sortIndex - n2.sortIndex);
  return providers;
};

export function formatNumber(number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
}

export function getFormattedNumberForEnergy(energy) {
  if (!energy) {
    return `0.00`;
  }

  return formatNumber(energy);
}

export const getExtraChargingSessionColumnsForCsv = (charging: BaseCharging) => {
  return {
    user: charging.user?.firstName + " " + charging.user?.lastName ?? "",
    energyDelivered: `${getFormattedNumberForEnergy(charging.energyDelivered)}`,
    cost: getFormattedCostByProvider(charging.cost, charging.provider),
    ...getVehicleSummariesAsObjectForCsv(charging.vehicle)
  };
};

export const getChargingSessionRowForCsv = (chargingRow: Charging | VehicleChargingDetail | OrganizationCharging) => {
  // We will add vehicle details as separated columns.
  const { connectorId, ...otherChargingData } = chargingRow;

  return {
    ...otherChargingData,
    ...getExtraChargingSessionColumnsForCsv(chargingRow as BaseCharging)
  };
};

export const getChargerProviders = () => {
  return [
    ChargingProvider.CHARGE_POINT,
    ChargingProvider.EVGO,
    ChargingProvider.ELECTRIFY_AMERICA,
    ChargingProvider.FLO,
    ChargingProvider.TESLA,
    ChargingProvider.HOME,
  ];
};

export const getChargingProviderForUrlQuery = (chargingProvider: ChargingProvider): string => {
  for (const key in ChargingProvider) {
    if (ChargingProvider[key] === chargingProvider) {
      return key;
    }
  }

  return "";
};
export const mapChargingProvidersForUrlQuery = (
  chargingProviders: ChargingProvider[]
): (keyof typeof ChargingProvider)[] => {
  return chargingProviders.map((n) => getChargingProviderForUrlQuery(n)) as (keyof typeof ChargingProvider)[];
};

export const mapChargingProvidersOnQueryToEnum = (chargingProvidersOnQuery: string[]): ChargingProvider[] => {
  return chargingProvidersOnQuery.map((noq) => ChargingProvider[noq]);
};

export const getChargingPaymentStateOptions = () => {
  return [
    { label: "Paid", value: ChargingPaymentState.PAID, textClass: "success-text" },
    { label: "Unpaid", value: ChargingPaymentState.UNPAID, textClass: "text-cherry-100" },
    { label: "Inapplicable", value: ChargingPaymentState.INAPPLICABLE, textClass: "" }
  ];
};
