import { Driver } from "./driver.types";
import { Period } from "./filter.types";
import { useVehicleDriverSchedulesAsync } from "../hooks/query/vehicle-driver-schedule-query.hooks";
import { SelectorStringOption } from "./editor.types";


export type Vehicle = {
  id: string;
  name: string;
  carBrand: string;
  carModel: string;
  carYear?: string;
  plateNumber: string;
  vin: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  deletedAt?: string;
  geotabConnectedAt: string;
  geotabConnectionState: VehicleConnectionState;
  geotabDisconnectedAt: string;
  smartcarConnectionState: VehicleConnectionState;
  smartcarConnectedAt: string;
  teslaConnectionState: VehicleConnectionState
  teslaConnectedAt: string
  imagesBaseUrl?: string
  users: Driver[];
  organizationId: string;
}


export type VehicleSmartcarInfo = {
  id: string;
  smartcarConnected: boolean;
  smartcarConnectedAt: Date;
  odometer: Odometer;
  charge: Charge;
  battery: Battery;
  location: Location;
}

export type Battery = {
  percentRemaining: number;
  range: number;
  meta: Meta
}

export type Charge = {
  isPluggedIn: boolean;
  state: string;
  meta: Meta
}

export type Meta = {
  fetchedAt: Date
}

export type Location = {
  latitude: number;
  longitude: number;
  address: string;
  meta: Meta
}

export type Odometer = {
  distance: number;
  meta: Meta
}


export type VehicleChargingDetail = {
  id: string;
  connectorId: string;
  chargerName: string;
  provider: string;
  status: string;
  type: string;
  cost: number;
  energyDelivered: string;
  duration: number;
  maxSessionDuration?: Date;
  startDate: number;
  paid: boolean;
  invoiceId: string;
  createdAt: string;
  updatedAt: string;
  user: Driver;
  vehicle: Vehicle;
}

export type VehicleChargingSummary = {
  public: Public;
  home: Home;
  total: Total;
}


type Home = {
  cost: Cost;
  energy: Energy;
  sessionSize: Energy;
}
type Public = Home

type Cost = {
  currency: null | string;
  count: number;
  percent: number;
}

type Energy = {
  count: number;
  percent: number;
}

type Total = {
  cost: Cost;
  energy: Energy;
  sessionSize: Energy;
  public_percent: number;
  home_percent: number;
}


export type VehicleSliceState = {
  vehicles: Vehicle[]
  isVehiclesLoading: boolean
};

export enum AddVehicleFormType {
  MANUAL,
  SMARTCAR
}

export enum SmartCarSelectType {
  BRAND = "BRAND",
  SINGLE = "SINGLE"
}

export type SmartCarState = {
  organizationId: string;
  selectType: SmartCarSelectType;
  permissions: string[];
  redirectUri: string;
}

export type VehicleSmartcarLocation = {
  id: string;
  location: Location;
}


export type ExchangeSmartCarStateAsyncPayload = {
  code: string,
  state: string
}


export enum VehicleDetailsEditFormMode {
  NAME, PLATE
}


export enum VehicleTabType {
  INFO,
  ASSIGNMENTS
}

export type MixedVehicleData = {
  vehicleSmartcarInfo: VehicleSmartcarInfo;
  vehicle: Vehicle;
}

export type CachedVehicleData = {
  lastFetchedAt: number,
  vehicleData: MixedVehicleData
}

export enum VehicleConnectionState {
  CONNECTED = "CONNECTED",
  NOT_CONNECTED = "NOT_CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  NOT_FOUND = "NOT_FOUND"
}



export type VehiclesTableFilters = {
  connection?: {
    $in: VehicleConnectionState[]
  },
  createdAt?: {
    $within?: Period,
    $gte?: string,
    $lt?: string
  },
  q?: {
    $eq: string
  }
}


export type VehicleDriverSchedulesDataAccessorHook = ReturnType<typeof useVehicleDriverSchedulesAsync>;


export type VehicleFormVehicleSelectorState = SelectorStringOption

