import FilterEditorCard from "components/filter/FilterEditorCard";
import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChargingFilters, ChargingProvider } from "types/charging.types";
import {
  getChargerInfo,
  getChargerProviders,
  mapChargingProvidersForUrlQuery,
  mapChargingProvidersOnQueryToEnum
} from "utils/charging.utils";

type Props = {};

function FilterProviderSelector(props: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<ChargingFilters>();

  const providersOnQuery = useMemo(
    () => mapChargingProvidersOnQueryToEnum(filterQuery?.provider?.$in ?? []),
    [filterQuery?.provider?.$in]
  );
  const [selectedProviders, setSelectedProviders] = useState<ChargingProvider[]>(providersOnQuery);
  const [variationCount, setVariationCount] = useState(0);

  const providerOptions = getChargerProviders();

  useEffect(() => {
    setSelectedProviders(providersOnQuery);
    setVariationCount(Math.min(filterQuery?.provider?.$in?.length ?? 0, providerOptions.length));
  }, [filterQuery, providerOptions.length, providersOnQuery]);

  const isSelectedProvider = useCallback(
    (provider: ChargingProvider) => {
      return selectedProviders.includes(provider);
    },
    [selectedProviders]
  );

  const handleProviderToggled = useCallback(
    (provider: ChargingProvider) => {
      if (isSelectedProvider(provider)) {
        setSelectedProviders(selectedProviders.filter((n) => n !== provider));
        return;
      }
      setSelectedProviders([...selectedProviders, provider]);
    },
    [isSelectedProvider, selectedProviders]
  );

  const handleSetProvidersOnQuery = useCallback(
    (providers: ChargingProvider[]) => {
      setFilterQuery((q) => {
        return {
          provider: {
            $in: mapChargingProvidersForUrlQuery(providers)
          }
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedProviders([]);
    handleSetProvidersOnQuery([]);
    setVariationCount(0);
  }, [handleSetProvidersOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetProvidersOnQuery(selectedProviders);
    setVariationCount(selectedProviders.length);
  }, [handleSetProvidersOnQuery, selectedProviders]);

  return (
    <FilterEditorCard
      className="my-1"
      title="Network"
      label="Network"
      description="Brand name of charging network on record"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >
      {providerOptions.map((provider, ix) => (
        <React.Fragment key={ix}>
          <FilterMenuCheckbox
            onToggle={() => handleProviderToggled(provider)}
            isChecked={isSelectedProvider(provider)}
            label={getChargerInfo(provider).label}
          />
        </React.Fragment>
      ))}
    </FilterEditorCard>
  );
}

export default FilterProviderSelector;
