import FilterMenu from "components/filter/FilterMenu";
import React from "react";
import FilterProviderSelector from "./FilterProviderSelector";
import ChargingSortingSelector from "./ChargingSortingSelector";
import FilterDateSelector from "components/filter/FilterDateSelector";
import FilterPaymentStateSelector from "./ChargingPaymentStateSelector";
import ChargingFilterVehicleSelector from "./ChargingFilterVehicleSelector";
import ChargingFilterDriverSelector from "./ChargingFilterDriverSelector";
import FilterDriverPayerStatusSelector from "../../driver/filter/FilterDriverPayerStatus";

type Props = {};

function ChargingFilterMenu(props: Props) {
  return (
    <FilterMenu
      selectors={[<ChargingFilterDriverSelector key={0} />, <ChargingFilterVehicleSelector key={1} />,
        <FilterProviderSelector key={2} />, <FilterDriverPayerStatusSelector key={3} />,
        <FilterPaymentStateSelector key={4} />,
        <FilterDateSelector key={5} />]}
      sortingSelector={<ChargingSortingSelector />}
    />
  );
}

export default ChargingFilterMenu;
