import UserAvatar from "components/avatar/UserAvatar";
import Card from "components/card/Card";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import LoadingSkeleton from "components/loading/LoadingSkeleton";

import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import { BlackBodyText0, BlackH6Text100 } from "components/text/Text";
import { OrganizationDetails } from "types/organization.types";
import OrganizationDetailsCardBlock from "./OrganizationDetailsCardBlock";
import OrganizationDetailsStatusEditor from "./form/OrganizationDetailsStatusEditor";
import OrganizationDetailsMultipleDriverAssignmentsStatusEditor
  from "./form/OrganizationDetailsMultipleDriverAssignmentsStatusEditor";
import OrganizationDetailsAutoSignupApprovalStatusEditor
  from "./form/OrganizationDetailsAutoSignupApprovalStatusEditor";
import OrganizationDetailsDummyVehicleAssignStatusEditor
  from "./form/OrganizationDetailsDummyVehicleAssignStatusEditor";

type Props = {
  readonly refetchOrganizationDetails: () => Promise<void>;
  readonly organizationDetails?: OrganizationDetails;
  readonly isLoading: boolean;
};

function OrganizationDetailsPersonalInformationCard({
                                                      organizationDetails,
                                                      isLoading,
                                                      refetchOrganizationDetails
                                                    }: Props) {
  return (
    <Col className="h-235px  flex-column justify-between ">
      <Card>
        <Row className="mb-2  align-items-center">
          {isLoading ? (
            <LoadingSkeleton count={2} />
          ) : (
            <>
              {" "}
              <UserAvatar userName={organizationDetails?.businessName} />{" "}
              <BlackH6Text100 className="ml-2">{organizationDetails?.businessName}</BlackH6Text100>
            </>
          )}
        </Row>

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="ID"
          leftTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackBodyText0} text={organizationDetails?.id} />}
          rightLabel="Status"
          rightTextOverwrite={
            <OrganizationDetailsStatusEditor
              refetchOrganizationDetails={refetchOrganizationDetails}
              organizationDetails={organizationDetails}
            />
          }
          rightText={organizationDetails?.status}
        />

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="Officer name"
          leftText={organizationDetails?.name ?? "-"}
        />

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="Phone number"
          leftTextOverwrite={
            <ClipboardWithCopyIcon TextComponent={BlackBodyText0} text={organizationDetails?.phoneNumber} />
          }
          rightLabel="Email"
          rightTextOverwrite={
            <ClipboardWithCopyIcon TextComponent={BlackBodyText0} text={organizationDetails?.email} />
          }

        />


        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="Allow multiple overlapping driver assignments to a vehicle"
          leftTextOverwrite={<OrganizationDetailsMultipleDriverAssignmentsStatusEditor
            refetchOrganizationDetails={refetchOrganizationDetails}
            organizationDetails={organizationDetails}
          />}
        />

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="Auto approve driver self signup requests"
          leftTextOverwrite={<OrganizationDetailsAutoSignupApprovalStatusEditor
            refetchOrganizationDetails={refetchOrganizationDetails}
            organizationDetails={organizationDetails}
          />}
        />

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="Auto assign dummy vehicle to new drivers"
          leftTextOverwrite={<OrganizationDetailsDummyVehicleAssignStatusEditor
            refetchOrganizationDetails={refetchOrganizationDetails}
            organizationDetails={organizationDetails}
          />}
          isBorderBottomVisible={false}
        />
      </Card>
    </Col>
  );
}

export default OrganizationDetailsPersonalInformationCard;
