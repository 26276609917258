export type OrganizationBase = {
  id: string
  email: string
  phoneNumber: string
  name: string
  businessName: string
  employerIdentificationNumber: any
  status: string
  active: boolean
  complete: boolean
  createdAt: string
  updatedAt: string
  vehiclesCount: number
}


export type OrganizationList = OrganizationBase[]


export type Limits = {
  id: string;
  chargingLimit: number;
}


export type OrganizationSettingsData = {
  allowOverlappingDriverAssignments: boolean,
  autoApproveSelfSignUps: boolean,
  autoAssignDummyVehicle: boolean,
  invoice: {
    netPaymentTerms: number
  },
  platformFees: {
    perVehicle: number,
    perDriver: number,
  },
  chargingDiscountRates: {
    organization: number
    driver: number
  },
  chargingServiceRates: {
    organization: number
    driver: number
  },
  chargingLateFeeRates: {
    driver: number
  },
  chargingBillingCycle: OrganizationFeeSettingsInvoiceCycle
}


export type OrganizationDetails = OrganizationBase & {
  limits: Limits;
  settings: {
    id: string,
    createdAt: string,
    updatedAt: string,
    data: OrganizationSettingsData
  };
  driversCount: number;
}

export type OrganizationSettings = {
  credit: number | null
  active: boolean
  settings: OrganizationSettingsData
}


export type OrganizationCharging = {
  id: string;
  connectorId: string;
  chargerName: string;
  provider: string;
  status: string;
  type: string;
  payer: number;
  cost: number;
  energyDelivered: string;
  duration: number;
  maxSessionDuration: null | number;
  startDate: number;
  paid: number;
  invoiceId: string;
  source: string;
  chargingType: string;
  createdAt: string;
  updatedAt: string;
}


export type OrganizationSliceState = {
  organizations: OrganizationBase[]
}


export enum OrganizationStatus {
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  PENDING_REVIEW = "PENDING_REVIEW"
}


export enum OrganizationFeeSettingsInvoiceCycle {
  Weekly = "weekly",
  Monthly = "monthly"
}


