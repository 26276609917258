// React
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";

// 3rd party
import { useTranslation } from "react-i18next";

// Hooks & Utils
// Types
// Components
import Row from "../../custom/Row";

import Col from "components/custom/Col";
import FilterSearchInput from "components/filter/FilterSearchInput";
import PageHeader from "components/header/PageHeader";
import FilterableTableCsvLinkButton from "components/table/FilterableTableCsvLinkButton";
import ServerPaginatedTable from "components/table/ServerPaginatedTable";
import { useOrganizationAsync, useOrganizationVehiclesAsync } from "hooks/query/organization-query.hooks";
import { useCommonTableColumns } from "hooks/table.hooks";
import { getOrganizationVehiclesAsync } from "services/organization.services";
import { CommonTableColumn } from "types/table.types";
import { Vehicle } from "types/vehicle.types";
import VehiclesTableFilterMenu from "./filter/VehiclesTableFilterMenu";

export type VehiclesTableColumn = CommonTableColumn<Vehicle>;

type Props = {};

const OrganizationVehiclesTable = ({}: Props) => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { organization, isLoading: isOrganizationLoading } = useOrganizationAsync();

  const { list, meta, isLoading } = useOrganizationVehiclesAsync();

  /**
   * Hooks
   */

  const { t } = useTranslation("common");

  const history = useHistory();

  const commonTableColumns = useCommonTableColumns();

  const columns = useMemo(
    () => [
      commonTableColumns.vehicle,
      commonTableColumns.vehicleSmartcarStatus,
      commonTableColumns.vehicleTeslaStatus,
      commonTableColumns.vehicleGeotabStatus,
      commonTableColumns.vehicleBrand,
      commonTableColumns.vehicleModel,
      commonTableColumns.vehicleDeletedAt
    ],
    [commonTableColumns]
  ) as VehiclesTableColumn[];

  /**
   * Handlers
   */

  /**
   * Effects
   */

  /**
   * Render
   */

  return (
    <div>
      <Col className="mb-2">
        <PageHeader
          isLoading={isOrganizationLoading}
          title={`${organization?.businessName} - Vehicles`}
          description={` View fleet organization's vehicles and their details`}
        />
      </Col>

      <Row className="justify-between my-2 align-center">
        <FilterSearchInput placeholder="Search vehicles" />
        <FilterableTableCsvLinkButton
          csvRowFormatter={(v) => v}
          totalPages={meta?.pagination.totalPages ?? 0}
          fileName="organization-vehicles"
          fetchListAsync={(query) => getOrganizationVehiclesAsync(organization?.id ?? "", query)}
        />
      </Row>

      <VehiclesTableFilterMenu />

      <ServerPaginatedTable
        getRoutePath={id => `/organizations/${organizationId}/vehicles/${id}`}
        paginationTotalRows={meta?.pagination.totalElements}
        columns={columns}
        data={list}
        isLoading={isLoading}
      />
    </div>
  );
};

export default OrganizationVehiclesTable;
