import AlertConfirmDialog from "components/alert/AlertConfirmDialog";
import IosSwitch from "components/input/switch/IosSwitch";
import { useCallback, useState } from "react";
import { updateOrganizationAsync } from "services/organization.services";
import { OrganizationDetails } from "types/organization.types";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../../utils";

type Props = {
  readonly organizationDetails?: OrganizationDetails;
  readonly refetchOrganizationDetails: () => Promise<void>;
};

function OrganizationDetailsAutoSignupApprovalStatusEditor({
                                                             organizationDetails,
                                                             refetchOrganizationDetails
                                                           }: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const toggleIsDialogOpen = () => setIsDialogOpen((is) => !is);

  const handleChangeStatusAsync = useCallback(async () => {
    if (!organizationDetails) {
      return;
    }
    const { id, limits, active } = organizationDetails;
    try {
      setIsLoading(true);
      const nextStatus = !organizationDetails.settings.data.autoApproveSelfSignUps;
      await updateOrganizationAsync(id, {
        active,
        credit: limits.chargingLimit,
        settings: {
          ...organizationDetails.settings.data,
          autoApproveSelfSignUps: nextStatus,
          autoAssignDummyVehicle: !nextStatus ? false : organizationDetails.settings.data.autoAssignDummyVehicle,
          allowOverlappingDriverAssignments: nextStatus ? true : organizationDetails.settings.data.allowOverlappingDriverAssignments
        }
      });

      await refetchOrganizationDetails();
      handleOpenSuccessAlert("Status updated successfully.");
      setIsDialogOpen(false)
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, organizationDetails, refetchOrganizationDetails]);


  const isChecked = organizationDetails?.settings?.data?.autoApproveSelfSignUps;

  return (
    <>
      <AlertConfirmDialog
        title={`Are you sure you want to ${isChecked ? "disable" : "allow"} it?`}
        isDialogOpen={isDialogOpen}
        toggleIsDialogOpen={toggleIsDialogOpen}
        onDialogConfirmed={handleChangeStatusAsync}
        isLoading={isLoading}
      />
      <IosSwitch isChecked={!!isChecked}
                 onSwitchStatusChanged={toggleIsDialogOpen} />
    </>
  );
}

export default OrganizationDetailsAutoSignupApprovalStatusEditor;
