import {
  getDriverPaymentResponsibilityLabelByPayerType,
  getFullNameByFirstNameAndLastName
} from "../../utils/driver.utils";


import Card from "../card/Card";
import Row from "../custom/Row";


import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import { BlackBodyText100 } from "../text/Text";
import ChargingSessionsDetailsPageCardBlock from "./ChargingDetailsPageCardBlock";
import VehiclePlaceholderIcon from "../../assets/icons/VehiclePlaceholderIcon";
import { Charging } from "../../types/charging.types";
import { useRoutes } from "../../hooks/router.hooks";
import UnderlinedTextButton from "../button/UnderlinedTextButton";

type Props = {
  charging: Charging
}

export default function ChargingDetailsPageUserCard({ charging }: Props) {
  const { user, vehicle } = charging;

  const { routes } = useRoutes();

  return (
    <Card>
      {vehicle && (
        <>
          <Row className="align-center mb-1">
            <div className="w-48px h-48px mr-1">
              <VehiclePlaceholderIcon />
            </div>
            <UnderlinedTextButton href={routes.organizationVehicleDetails(vehicle.id)}
                                  text={vehicle?.name} />
          </Row>

          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Vehicle"
            leftText={vehicle?.carBrand}
            rightLabel="Model"
            rightText={vehicle?.carModel}
          />
        </>
      )}


      {vehicle && (
        <ChargingSessionsDetailsPageCardBlock
          leftLabel="License Plate"
          leftText={vehicle?.plateNumber ? vehicle.plateNumber : "-"}
          rightLabel={"VIN"}
          rightTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackBodyText100} text={vehicle?.vin} />}
        />
      )}


      <ChargingSessionsDetailsPageCardBlock
        leftLabel="Driver name"
        leftTextOverwrite={
          user ? <UnderlinedTextButton
            textClassName={"text-black-100 fs-16px"}
            href={routes.organizationDriverDetails(user.id)}
            text={getFullNameByFirstNameAndLastName(user?.firstName, user?.lastName)} /> : "-"
        }
        rightLabel="Payment Responsibility"
        rightText={getDriverPaymentResponsibilityLabelByPayerType(charging?.payer)}
      />

    </Card>
  );
}

