import LoadingSkeletonForPaginatedTable from "components/loading/LoadingSkeletonForPaginatedTable";
import React, { useRef, useState } from "react";
import DataTable, { TableProps } from "react-data-table-component";
import TablePlaceholder from "../placeholder/TablePlaceholder";
import { useTableRowHtmlElementReplacementWithRef } from "../../hooks/table.hooks";


type Props = TableProps<any> & {
  noDataPlaceholder?: React.JSX.Element;
  progressComponents?: React.JSX.Element;
  getRoutePath?: (id: string) => string | undefined
};

function ClientPaginatedTable({ noDataPlaceholder, progressComponents, getRoutePath, ...props }: Props) {
  const [page, setPage] = useState(0);


  const tableRef = useRef<HTMLDivElement>(null);

  useTableRowHtmlElementReplacementWithRef(getRoutePath, tableRef, page);

  return (
    <div ref={tableRef} className={`${props.progressPending && "mt-2"}`}>
      <DataTable
        onChangePage={setPage}
        progressComponent={progressComponents ?? <LoadingSkeletonForPaginatedTable />}
        noDataComponent={
          noDataPlaceholder ?? <TablePlaceholder />
        }
        paginationRowsPerPageOptions={[20, 50]}
        paginationPerPage={20}
        pagination
        highlightOnHover
        responsive={true}
        className="rounded-xxl custom-table-border overflow-auto"
        style={{ borderRadius: 10 }}
        {...props}
      />
    </div>
  );
}

export default ClientPaginatedTable;
